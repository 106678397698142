import { ethers, BigNumber } from "ethers";
import { addresses } from "../constants";
import { abi as ierc20ABI } from "../abi/IERC20.json";
import { abi as SupABI } from "../abi/SupremeContract.json";
import { abi as LotteryABI } from "../abi/lotteryContract.json";
import { clearPendingTxn, fetchPendingTxns, getStakingTypeText } from "./PendingTxnsSlice";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchAccountSuccess, getBalances } from "./AccountSlice";
import { error, info } from "../slices/MessagesSlice";
import { IActionValueAsyncThunk, IChangeApprovalAsyncThunk, IJsonRPCError } from "./interfaces";
import { segmentUA } from "../helpers/userAnalyticHelpers";

interface IUAData {
  address: string;
  value: string;
  approved: boolean;
  txHash: string | null;
  type: string | null;
}

function alreadyApprovedToken(token: string, stakeAllowance: BigNumber, unstakeAllowance: BigNumber) {
  // set defaults
  let bigZero = BigNumber.from("0");
  let applicableAllowance = bigZero;

  // determine which allowance to check
  if (token === "sup") {
    applicableAllowance = stakeAllowance;
  } else if (token === "doges") {
    applicableAllowance = unstakeAllowance;
  }

  // check if allowance exists
  if (applicableAllowance.gt(bigZero)) return true;

  return false;
}

export const changeApproval = createAsyncThunk(
  "stake/changeApproval",
  async ({ provider, address, networkID }: IChangeApprovalAsyncThunk, { dispatch }) => {
    if (!provider) {
      dispatch(error("Please connect your wallet!"));
      return;
    }

    const signer = provider.getSigner();
    const supContract = new ethers.Contract(addresses[networkID].SUPREME_ADDRESS as string, SupABI, signer);
    let approveTx;
    let supAllowance = await supContract.allowance(address, addresses[networkID].LOTTERY_ADDRESS);
    // return early if approval has already happened
    if (supAllowance.gt(BigNumber.from("0"))) {
      dispatch(info("Approval completed."));
      return dispatch(
        fetchAccountSuccess({
          staking: {            
            supreme: +supAllowance,
          },
        }),
      );
    }

    try {
        approveTx = await supContract.approve(addresses[networkID].LOTTERY_ADDRESS,ethers.utils.parseUnits("10000000000000000000000000000000000000", "gwei").toString(),);
        const text = "Approve Sup";
        const pendingTxnType =  "approve";
        if (approveTx) {
            dispatch(fetchPendingTxns({ txnHash: approveTx.hash, text, type: pendingTxnType }));
            await approveTx.wait();
        }
    } catch (e: unknown) {
        dispatch(error((e as IJsonRPCError).message));
        return;
    } finally {
        if (approveTx) {
            dispatch(clearPendingTxn(approveTx.hash));
        }
    }
    supAllowance = await supContract.allowance(address, addresses[networkID].LOTTERY_ADDRESS);
    dispatch(getBalances({ address, networkID, provider }));
    return dispatch(
      fetchAccountSuccess({
        staking: {
          supreme: +supAllowance,
        },
      }),
    );
  },
);
export const deposit = createAsyncThunk(
    "stake/deposit",
    async ({ value, provider, address, networkID }: IActionValueAsyncThunk, { dispatch }) => {
        if (!provider) {
            dispatch(error("Please connect your wallet!"));
            return;
        }
        const signer = provider.getSigner();
        const lotteryContract = new ethers.Contract(addresses[networkID].LOTTERY_ADDRESS as string, LotteryABI,signer);
        let stakeTx;
        let uaData: IUAData = {
            address: address,
            value: value,
            approved: true,
            txHash: null,
            type: null,
        };
        try {
            stakeTx = await lotteryContract.enter(value);
            uaData.txHash = stakeTx.hash;
            dispatch(fetchPendingTxns({ txnHash:stakeTx.hash, text: "enter supreme", type: "enter"}));
            await stakeTx.wait();
        } catch (e: unknown) {
            uaData.approved = false;
            const rpcError = e as IJsonRPCError;
            console.log("rcpError",rpcError.data);
            if (rpcError.code === -32603 && rpcError.message.indexOf("ds-math-sub-underflow") >= 0) {
                dispatch(error("You may be trying to stake more than your balance! Error code: 32603. Message: ds-math-sub-underflow"));
            } else {
                dispatch(error(rpcError.data.message));
            }
            return;
        } finally {
            if (stakeTx) {
                segmentUA(uaData);
                dispatch(clearPendingTxn(stakeTx.hash));
            }
        }
    }
);
// export const changeStake = createAsyncThunk(
//   "stake/changeStake",
//   async ({ action, value, provider, address, networkID }: IActionValueAsyncThunk, { dispatch }) => {
//     if (!provider) {
//       dispatch(error("Please connect your wallet!"));
//       return;
//     }

//     const signer = provider.getSigner();
//     const staking = new ethers.Contract(
//       addresses[networkID].STAKING_ADDRESS as string,
//       StakingABI,
//       signer,
//     );
//     const stakingHelper = new ethers.Contract(
//       addresses[networkID].STAKING_HELPER_ADDRESS as string,
//       StakingHelperABI,
//       signer,
//     );

//     let stakeTx;
//     let uaData: IUAData = {
//       address: address,
//       value: value,
//       approved: true,
//       txHash: null,
//       type: null,
//     };
//     try {
//       if (action === "stake") {
//         uaData.type = "stake";
//         stakeTx = await stakingHelper.stake(ethers.utils.parseUnits(value, "gwei"), address); // ychm staking helper function has 2 parameters
//       } else {
//         uaData.type = "unstake";
//         stakeTx = await staking.unstake(ethers.utils.parseUnits(value, "gwei"), true);
//       }
//       const pendingTxnType = action === "stake" ? "staking" : "unstaking";
//       uaData.txHash = stakeTx.hash;
//       dispatch(fetchPendingTxns({ txnHash: stakeTx.hash, text: getStakingTypeText(action), type: pendingTxnType }));
//       await stakeTx.wait();
//     } catch (e: unknown) {
//       uaData.approved = false;
//       const rpcError = e as IJsonRPCError;
//       if (rpcError.code === -32603 && rpcError.message.indexOf("ds-math-sub-underflow") >= 0) {
//         dispatch(
//           error("You may be trying to stake more than your balance! Error code: 32603. Message: ds-math-sub-underflow"),
//         );
//       } else {
//         dispatch(error(rpcError.message));
//       }
//       return;
//     } finally {
//       if (stakeTx) {
//         segmentUA(uaData);
//         dispatch(clearPendingTxn(stakeTx.hash));
//       }
//     }
//     dispatch(getBalances({ address, networkID, provider }));
//   },
// );
