import { ethers } from "ethers";
import { addresses } from "../constants";
import { abi as ierc20Abi } from "../abi/IERC20.json";
import { abi as lotteryAbi } from "../abi/lotteryContract.json";
import { setAll } from "../helpers";

import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { RootState } from "src/store";
import { IBaseAddressAsyncThunk } from "./interfaces";

export const getBalances = createAsyncThunk(
  "account/getBalances",
  async ({ address, networkID, provider }: IBaseAddressAsyncThunk) => {
    const supremeContract = new ethers.Contract(addresses[networkID].SUPREME_ADDRESS as string, ierc20Abi, provider);
    const supremeBalance = await supremeContract.balanceOf(address);
    const lotteryContract = new ethers.Contract(addresses[networkID].LOTTERY_ADDRESS as string, lotteryAbi, provider);
    const lotteryDeposit = await lotteryContract.userEntries(address);
    const allowance = await supremeContract.allowance(address,addresses[networkID].LOTTERY_ADDRESS);
    return {
      balances: {
        supreme: ethers.utils.formatUnits(supremeBalance, "ether"),
        lotteryDeposit: lotteryDeposit,
        allowance: allowance
      },
    };
  },
);


export const loadAccountDetails = createAsyncThunk(
  "account/loadAccountDetails",
  async ({ networkID, provider, address }: IBaseAddressAsyncThunk) => {
    let supremeBalance = 0;
    const supremeContract = new ethers.Contract(addresses[networkID].SUPREME_ADDRESS as string, ierc20Abi, provider);
    supremeBalance = await supremeContract.balanceOf(address);
    const lotteryContract = new ethers.Contract(addresses[networkID].LOTTERY_ADDRESS as string, lotteryAbi, provider);
    const lotteryDeposit = await lotteryContract.userEntries(address);
    const allowance = await supremeContract.allowance(address,addresses[networkID].LOTTERY_ADDRESS);
    return {
      balances: {
        supreme: ethers.utils.formatUnits(supremeBalance, "ether"),
        lotteryDeposit: lotteryDeposit,
        allowance: allowance
      },
    };
  },
);

export interface IUserBondDetails {
  allowance: number;
  interestDue: number;
  bondMaturationBlock: number;
  pendingPayout: string; //Payout formatted in gwei.
}


interface IAccountSlice {
  bonds: { [key: string]: IUserBondDetails };
  balances: {
    supreme: string;
  };
  loading: boolean;
}
const initialState: IAccountSlice = {
  loading: false,
  bonds: {},
  balances: { supreme: "" },
};

const accountSlice = createSlice({
  name: "account",
  initialState,
  reducers: {
    fetchAccountSuccess(state, action) {
      setAll(state, action.payload);
    },
  },
  extraReducers: builder => {
    builder
      .addCase(loadAccountDetails.pending, state => {
        state.loading = true;
      })
      .addCase(loadAccountDetails.fulfilled, (state, action) => {
        setAll(state, action.payload);
        state.loading = false;
      })
      .addCase(loadAccountDetails.rejected, (state, { error }) => {
        state.loading = false;
        console.log(error);
      })
      .addCase(getBalances.pending, state => {
        state.loading = true;
      })
      .addCase(getBalances.fulfilled, (state, action) => {
        setAll(state, action.payload);
        state.loading = false;
      })
      .addCase(getBalances.rejected, (state, { error }) => {
        state.loading = false;
        console.log(error);
      })
      
  },
});

export default accountSlice.reducer;

export const { fetchAccountSuccess } = accountSlice.actions;

const baseInfo = (state: RootState) => state.account;

export const getAccountState = createSelector(baseInfo, account => account);
