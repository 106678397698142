export const THE_GRAPH_URL = "https://api.thegraph.com/subgraphs/name/wkich/mama-subgraph";
export const EPOCH_INTERVAL = 9600;

// NOTE could get this from an outside source since it changes slightly over time
export const BLOCK_RATE_SECONDS = 1;

export const TOKEN_DECIMALS = 18;

interface IAddresses {
  [key: number]: { [key: string]: string };
}

export const addresses: IAddresses = {
  56: {
    // SUPREME_ADDRESS: "0xe6e5F58172E5166f345d1dfa5F69E27Bf31e5F35",
    // SUPREME_BNB_LP_ADDRESS: "0xC18aEd928bCAAe3d2D000fb4DE41797F31628877",
    // WBNB_ADDRESS: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
    // WBNB_USDT_LP_ADDRESS : "0x16b9a82891338f9bA80E2D6970FddA79D1eb0daE",
    // TREASURY_ADDRESS : "0x03861288cc5c2714e8c00Fc63ee6b0A0b306F3e9",
    // RFV_ADDRESS : "0x03861288cc5c2714e8c00Fc63ee6b0A0b306F3e9"
    SUPREME_ADDRESS: "0x983B9Ab65CCDccFdAEA3F7A973De6Fed4E0546B3",
    SUPREME_BNB_LP_ADDRESS: "0x84b0eDcEBC57535FC7E122B9f052FA2Ab8AEC1fc",
    WBNB_ADDRESS: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
    WBNB_USDT_LP_ADDRESS : "0x16b9a82891338f9bA80E2D6970FddA79D1eb0daE",
    TREASURY_ADDRESS : "0xE28A3c2Feb553DC3C29f2982D15f92309041f5F9",
    RFV_ADDRESS : "0xecE4e55220aCA34617E6FEAD3d54d2b24b41f2a3",
    //LOTTERY_ADDRESS : "0xfddd1946bbBEbB50dc9c40BA04b0A7cCe61342Ee" 0x365c6624eba806bd51674b8e3e432a5d0ab584e7 0x25C9E7fD0888c1ec08189ebE980F70f70bE2589d
    LOTTERY_ADDRESS : "0x25C9E7fD0888c1ec08189ebE980F70f70bE2589d",
    LOCKED_ADDRESS: "0x7ee058420e5937496F5a2096f04caA7721cF70cc"
  },
};

export const messages = {
  please_connect: "Please connect your wallet to the Avalanche network to use Wonderland.",
  please_connect_wallet: "Please connect your wallet.",
  try_mint_more: (value: string) => `You're trying to mint more than the maximum payout available! The maximum mint payout is ${value} CROP.`,
  before_minting: "Before minting, enter a value.",
  existing_mint:
      "You have an existing mint. Minting will reset your vesting period and forfeit any pending claimable rewards. We recommend claiming rewards first or using a fresh wallet. Do you still wish to proceed?",
  before_stake: "Before staking, enter a value.",
  before_unstake: "Before un staking, enter a value.",
  tx_successfully_send: "Your transaction was successful",
  your_balance_updated: "Your balance was successfully updated",
  nothing_to_claim: "You have nothing to claim",
  something_wrong: "Something went wrong",
  switch_to_avalanche: "Switch to the Avalanche network?",
  slippage_too_small: "Slippage too small",
  slippage_too_big: "Slippage too big",
  your_balance_update_soon: "Your balance will update soon",
};


 