import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from 'styled-components';
import {
  Grid,
  Paper,
  Typography,
  Zoom,
  Button,
  Box,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment
} from "@material-ui/core";
import { trim } from "../../helpers";
import "./lottery.scss";
import { Skeleton } from "@material-ui/lab";
import moment from 'moment';
import ProgressCountdown from "../../components/ProgressCountdown"

import { useWeb3Context } from "src/hooks/web3Context";
// import { t, Trans } from "@lingui/macro";
import { changeApproval, deposit } from "../../slices/StakeThunk";
import { ethers } from "ethers";
import { error } from "../../slices/MessagesSlice";
import { isPendingTxn, txnButtonText } from "src/slices/PendingTxnsSlice";

function Lottery() {
  const dispatch = useDispatch();
  const { provider, address, connected, connect, chainID } = useWeb3Context();
  let modalButton = [];

  modalButton.push(
    <Button variant="contained" color="primary" className="connect-button" onClick={connect} key={1}>
      Connect Wallet
    </Button>,
  );

  
  const stakeAllowance = useSelector(state => {
    return state.account.balances && state.account.balances.allowance;
  });
  const hasAllowance = useCallback(
    token => {
      return stakeAllowance > 0;
    },
    [stakeAllowance],
  );

  // const isAllowanceDataLoading = stakeAllowance == null ;
  const isAllowanceDataLoading = 0;

  const [zoomed, setZoomed] = useState(false);
 
  const isAppLoading = useSelector(state => state.app.loading);
 
  const marketPrice = useSelector(state => {
    return state.app.marketPrice;
  });
  

  const supremeBalance = useSelector(state => {
    return state.account.balances && state.account.balances.supreme;
  });

  const nextRewardYield = useSelector(state => {
    return state.app.stakingRebase;
  });
  const dailyAPY =  useSelector(state => {
    return state.app.DailyAPY;
  });

  const ThirtyDayRate =  useSelector(state => {
    return state.app.ThirtyDayRate;
  });

  const TotalAPY = useSelector(state => {
    return state.app.totalAPY;
  });

  const nextRebase = useSelector(state => {
    return state.app.nextRebase;
  });

  const lotteryId = useSelector(state => {
    return state.app.lotteryId;
  });
  const lotteryEndTime = useSelector(state => {
    return state.app.lotteryEndTime;
  });
  const totalEntries = useSelector(state => {
    return state.app.totalEntries;
  });
  const previousWinner = useSelector(state => {
    return state.app.previousWinner;
  });
  const yourDeposit = useSelector(state => {
    console.log("lotteryDeposit", state.account.balances.lotteryDeposit);
    return state.account.balances && state.account.balances.lotteryDeposit;
  });
  const pendingTransactions = useSelector(state => {
    return state.pendingTransactions;
  });
  const [quantity, setQuantity] = useState("");
  const setMax = () => {
      setQuantity(Number(trim(supremeBalance/100000,0)));
  };
  const onSeekApproval = async token => {
    return dispatch(changeApproval({ address, provider, networkID: chainID }));
  };
  const onDeposit = async action=> {
    if (isNaN(quantity) || quantity === 0 || quantity === "")
      return dispatch(error("Please enter a value!"));

    // let gweiValue = ethers.utils.parseUnits(trim(quantity,2), "gwei");
    const q = Number(trim(quantity*100000,0));
    const b = Number(trim(supremeBalance*100000,0));
    console.log("quantity, supreme",q, b);
    if ( q>b )
      return dispatch(error("You cannot stake more than your SUP balance."));
    await dispatch(deposit({ address, value: quantity.toString(), provider, networkID: chainID }));
  }
  const CustomSpan = styled.span`
  width: fit-content;
  background-color: #fd3d56;
  -webkit-background-clip: text;
  background-clip: text;
  text-shadow: #FF3030f0 1px 0 10px;
  -webkit-text-fill-color: transparent;
  font-weight: 900;
`


  const CustomHeaderText = styled(Typography)`
    margin-top:2px !important;
    margin-bottom:2px !important;
  `

  return (
    <div id="lottery-view">
      <Grid container spacing={1}>
        <Grid item lg={6} md={12} sm={12} xs={12} className="olympus-card">
          <Zoom in={true}>
            <Paper className="supreme-card">
              <div className="card-header">
                <Typography variant="body1">Lottery ID</Typography>
                <Typography variant="h5" >{isAppLoading ? <Skeleton width="80px" /> : <>{lotteryId?trim(lotteryId, 0):"0"}</>}</Typography>
              </div>
            </Paper>
          </Zoom>
        </Grid>
        <Grid item lg={6} md={12} sm={12} xs={12}>
          <Zoom in={true}>
            <Paper className="supreme-card">
              <div className="card-header">
                  <Typography variant="body1">Time Remaining</Typography> 
                  <Typography variant="h5" >
                     <ProgressCountdown str="Finished" base={moment().toDate()} unixDeadline={lotteryEndTime-60} description="Lottery End" />
                  </Typography>                             
              </div>
             
            </Paper>
          </Zoom>
        </Grid>
        <Grid item lg={6} md={12} sm={12} xs={12}>
          <Zoom in={true}>
            <Paper className="supreme-card">
              <div className="card-header">
                <Typography variant="body1">Total Supreme Deposited</Typography>
                <Typography variant="h5">{isAppLoading ? <Skeleton width="80px" /> : <>{totalEntries?trim("$"+totalEntries*marketPrice*100000, 12):"$0.00"}</>}</Typography>    
                <Typography variant="body1">{isAppLoading ? <Skeleton width="80px" /> : <>{totalEntries?trim(totalEntries, 2):"0.00"} Entry</>}</Typography>                                          
              </div>             
            </Paper>
          </Zoom>
        </Grid>

        

        <Grid item lg={6} md={12} sm={12} xs={12}>
          <Zoom in={true}>
            <Paper className="supreme-card">
              <div className="card-header">
                  <Typography variant="body1">Your Supreme Deposit</Typography>
                  <Typography variant="h5">{isAppLoading ? <Skeleton width="80px" /> : <>{yourDeposit?trim("$"+yourDeposit*marketPrice*100000,12):"$0.00"}</>}</Typography>    
                  <Typography variant="body1">{isAppLoading ? <Skeleton width="80px" /> : <>{yourDeposit?trim(yourDeposit,2):"0"} Entry</>}</Typography>                              
              </div>             
            </Paper>
          </Zoom>
        </Grid>       
      </Grid> 
      <Grid container spacing={1}>
        <Paper className="supreme-card">
          <Typography style={{marginBottom:'10px'}} variant="h4">
            Winner is selected with a random number using Chainlink VRF.    
          </Typography>
          <Box display="flex" flexWrap="wrap" justifyContent="space-between" alignItems="center">
            <Box className="metric price">
              <CustomHeaderText variant="h6" color="textSecondary">
                Share
              </CustomHeaderText>
              <Typography variant="h5">
                %
              </Typography>
            </Box>

            <Box className="metric wsoprice">
              <CustomHeaderText variant="h6" color="textSecondary">
                Winner
              </CustomHeaderText>
              <Typography variant="h5">
                90%
              </Typography>
            </Box>

            <Box className="metric circ" >
              <CustomHeaderText variant="h6" color="textSecondary">
                Marketing
              </CustomHeaderText>
              <Typography variant="h5">
                3%
              </Typography>
            </Box>

            <Box className="metric bpo">
              <CustomHeaderText variant="h6" color="textSecondary">
                Dev
              </CustomHeaderText>
              <Typography variant="h5">
                3%              
              </Typography>
            </Box>
            <Box className="metric bpo">
              <CustomHeaderText variant="h6" color="textSecondary">
                Burn
              </CustomHeaderText>
              <Typography variant="h5">
                4%              
              </Typography>
            </Box>
          </Box>
        </Paper>
        </Grid>
      <Grid container direction="column" spacing={1}> 
        <div className="staking-area">
          {!address ? (
            <div className="stake-wallet-notification">
              <div className="wallet-menu" id="wallet-menu">
                {modalButton}
              </div>
              <Typography variant="h6">
                Connect your wallet to deposit <b>SUP)</b>
              </Typography>
            </div>
          ) : (
            <>
              <Box className="stake-action-area">
                <Box className="stake-action-row " display="flex" alignItems="center">
                  {address && !isAllowanceDataLoading ? (
                    (!hasAllowance("sup") ) ? (
                      <Box className="help-text">
                        <Typography variant="body1" className="stake-note" color="textSecondary">
                              First time depositing <b>SUP</b>?
                              <br />
                              Please approve <b>SUP</b> to use your <b>SUP</b>{" "}
                              for lottery.
                        </Typography>
                      </Box>
                    ) : (
                      <>
                        <Box className="help-text">
                          <Typography variant = "body1" style={{width:'200px'}} color="textSecondary">
                            1 Entry = 100,000 SUP
                          </Typography>
                        </Box>
                        <FormControl className="sup-input" variant="outlined" color="primary">
                          <InputLabel htmlFor="amount-input"></InputLabel>
                          <OutlinedInput
                            id="amount-input"
                            type="number"
                            placeholder="Enter an amount"
                            className="stake-input"
                            value={trim(quantity,0)}
                            onChange={e => setQuantity(e.target.value)}
                            labelWidth={0}
                            endAdornment={
                              <InputAdornment position="end">
                                <Button variant="text" onClick={setMax} color="inherit">
                                  Max
                                </Button>
                              </InputAdornment>
                            }
                          />
                        </FormControl>
                      </>
                    )
                  ) : (
                    <Skeleton width="150px" />
                  )}

                  {isAllowanceDataLoading ? (
                    <Skeleton />
                  ) : address && hasAllowance("sup") ? (
                    <Button
                      className="stake-button"
                      variant="contained"
                      color="primary"
                      disabled={isPendingTxn(pendingTransactions, "enter")}
                      onClick={() => {
                        onDeposit("stake");
                      }}
                    >
                      {/* Deposit */}
                      {txnButtonText(pendingTransactions, "enter", "Enter")}
                    </Button>
                  ) : (
                    <Button
                      className="stake-button"
                      variant="contained"
                      color="primary"
                      disabled={isPendingTxn(pendingTransactions, "approve")}
                      onClick={() => {
                        onSeekApproval("sup");
                      }}
                    >
                      {/* Approve */}
                      {txnButtonText(pendingTransactions, "approve", "Approve")}
                    </Button>
                  )}
                </Box>
              </Box>
            </>
          )}
        </div>
      </Grid>
      <Zoom in={true}>
        <Paper className={"supreme-card"}>
        <Grid container direction="column" spacing={2}> 
          <div className="staking-area">                             
              <>
                <div className={"lottery-user-data"}>
                  <div className="data-row">
                    <Typography variant="body1">Previous Winner</Typography>
                    <Typography variant="body1">{isAppLoading ? <Skeleton width="80px" /> : <>{previousWinner}</>}</Typography>
                  </div>
                </div>
              </>                
          </div>
        </Grid>
        </Paper>
      </Zoom>
    </div>
  );
}

export default Lottery;
