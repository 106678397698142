import React from 'react';
// import styled from 'styled-components';
// import { Heading, Text } from '@pancakeswap-libs/uikit'
import Countdown, { CountdownRenderProps } from 'react-countdown';

interface ProgressCountdownProps {
  str: string;
  base: Date;
  unixDeadline: string;
  description: string;
}

// const Coutdown = styled(Text)`
//   color: red;
//   font-size: 35px;
//   font-weight: 900;
//   text-shadow: 2px 2px 3px white;
// `

const ProgressCountdown: React.FC<ProgressCountdownProps> = ({ str, base, unixDeadline, description }) => {
  const deadline = new Date(+unixDeadline * 1000);

  const percentage =
    Date.now() >= deadline.getTime()
      ? 100
      : ((Date.now() - base.getTime()) / (deadline.getTime() - base.getTime())) * 100;

  const countdownRenderer = (countdownProps: CountdownRenderProps) => {
    const { days, hours, minutes, seconds } = countdownProps;
    const timestamp = ((days * 24 + hours) * 3600) + (minutes * 60) + seconds;
    const h = String(hours+days*24);
    const m = String(minutes);
    const s = String(seconds);
    if (timestamp > 0) {
      return (
        <div>
          {/* <Text>
              {description}
            </Text> */}
          {/* <Coutdown>
              {days}:{hours}:{m.padStart(2, '0')}:{s.padStart(2, '0')}
            </Coutdown> */}
          <div className="counter">
            <span>{h.padStart(2, '0')}</span>:<span>{m.padStart(2, '0')}</span>:<span>{s.padStart(2, '0')}</span>

          </div>
        </div>
      );
    }
    return (
    <div className="counter">
      {str}
    </div>
    );

  };
  return (
    <Countdown key={new Date().getTime()} date={deadline} renderer={countdownRenderer} />
  );
};

export default ProgressCountdown;
