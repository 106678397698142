import { SvgIcon, Link } from "@material-ui/core";
import { ReactComponent as GitHub } from "../../assets/icons/github.svg";
import { ReactComponent as Medium } from "../../assets/icons/medium.svg";
import { ReactComponent as Twitter } from "../../assets/icons/twitter.svg";
import { ReactComponent as Discord } from "../../assets/icons/discord.svg";
import { ReactComponent as Telegram } from "../../assets/icons/telegram.svg";
import { ReactComponent as Reedit } from "../../assets/icons/reedit.svg";

export default function Social() {
  return (
    <div className="social-row">

      <Link href="https://twitter.com/Supreme_xyz" target="_blank">
        <SvgIcon color="primary" component={Twitter} />
      </Link>
      {/* <Link href="https://discord.gg/supreme" target="_blank">
        <SvgIcon color="primary" component={Discord} /> 
      </Link>*/}
      <Link href="https://t.me/supremefinance_xyz" target="_blank">
        <SvgIcon color="primary" component={Telegram} viewBox="0 0 24 24" />
      </Link>
      {/* <Link href="https://medium.com/@supreme_" target="_blank">
        <SvgIcon color="primary" component={Medium} />
      </Link> */}
      <Link href="https://github.com/KISHIELD/Audits/blob/main/KISHIELD_SUPREME_Audit_20220401.pdf" target="_blank">
        <SvgIcon color="primary" component={GitHub} viewBox="0 0 24 24" />
      </Link>


    </div>
  );
}
