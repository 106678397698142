import { BigNumber, ethers } from "ethers";
import { addresses } from "../constants";
import { abi  } from "../abi/SupremeContract.json";
import { abi as lotteryAbi  } from "../abi/lotteryContract.json";
import { abi as ierc20Abi } from "../abi/IERC20.json";
import { setAll, getTokenPrice, getMarketPrice, getWBNBPrice } from "../helpers";
import { createSlice, createSelector, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "src/store";
import { IBaseAsyncThunk } from "./interfaces";

const initialState = {
  loading: false,
  loadingMarketPrice: false,
};

export const loadAppDetails = createAsyncThunk(
  "app/loadAppDetails",
  async ({ networkID, provider }: IBaseAsyncThunk, { dispatch }) => {
    const marketPrice = ((await getMarketPrice({networkID, provider})) );
    const wBNBPrice = ((await getWBNBPrice({networkID, provider})) );
    const supremeContract = new ethers.Contract(addresses[networkID].SUPREME_ADDRESS as string, abi, provider);
    const total = await supremeContract.totalSupply();
    const circSupply = (await supremeContract.getCirculatingSupply()-await supremeContract.balanceOf(addresses[networkID].LOCKED_ADDRESS)) / (Math.pow(10, 18));
    const nextRebase = await supremeContract.nextRebase();
    const rewardYield = await supremeContract.rewardYield();
    const totalSupply = total / Math.pow(10, 18);
    const marketCap = marketPrice * circSupply;
    const wbnbContract = new ethers.Contract(addresses[networkID].WBNB_ADDRESS as string, ierc20Abi, provider);
    const blv = Number(await wbnbContract.balanceOf(addresses[networkID].SUPREME_BNB_LP_ADDRESS)) / (Math.pow(10,18)) * wBNBPrice;
    
    const mvt= Number(await provider.getBalance(addresses[networkID].TREASURY_ADDRESS)) / (Math.pow(10,18)) * wBNBPrice;
    // const num_mvt = mvt.toNumber() * wBNBPrice;
    const rfv = Number(await provider.getBalance(addresses[networkID].RFV_ADDRESS)) / (Math.pow(10,18)) * wBNBPrice;
    const treasuryBNB = Number(await provider.getBalance(addresses[networkID].TREASURY_ADDRESS));
    const lpBNB = Number(await wbnbContract.balanceOf(addresses[networkID].SUPREME_BNB_LP_ADDRESS));
    const backedLiquidity = 100 * treasuryBNB / lpBNB;

    const lotteryContract = new ethers.Contract(addresses[networkID].LOTTERY_ADDRESS as string, lotteryAbi, provider);
    const lotteryId = await lotteryContract.lotteryID();
    console.log("lotteryId", lotteryId);
    const lotteryEndTime = await lotteryContract.endTime(Number(lotteryId));
    const isLotteryActive = await lotteryContract.isActive();
     const previousWinner = await lotteryContract.previousWinner();// "0x03861288cc5c2714e8c00Fc63ee6b0A0b306F3e9"
    const totalEntries = await lotteryContract.totalEntries();

    if (!provider) {
      console.error("failed to connect to provider, please connect your wallet");
      return {
        marketPrice,
        marketCap,
        circSupply,
        totalSupply,
        blv ,
        mvt ,
        rfv ,
        backedLiquidity,
        lotteryId,
        lotteryEndTime,
        isLotteryActive,
        totalEntries,
        previousWinner
      };
    }
    const currentTime = Math.floor(Date.now()/1000);
    const stakingRebase = rewardYield / 10000000000;
    const ThirtyDayRate = Math.pow(1 + stakingRebase, 30 * 24 * 6) - 1;
    const totalAPY = Math.pow(1 + stakingRebase, 365 * 24 * 6)-1 ;
    const DailyAPY = Math.pow(1 + stakingRebase, 24 * 6) -1 ;

    return {
      DailyAPY,
      ThirtyDayRate,
      totalAPY,
      currentTime,      
      stakingRebase,
      marketCap,
      marketPrice,
      circSupply,
      totalSupply,
      blv,
      mvt,
      rfv,
      nextRebase,
      backedLiquidity,
      lotteryId,
      lotteryEndTime,
      isLotteryActive,
      totalEntries,
      previousWinner
    } as IAppData;
  },
);

export const findOrLoadMarketPrice = createAsyncThunk(
  "app/findOrLoadMarketPrice",
  async ({ networkID, provider }: IBaseAsyncThunk, { dispatch, getState }) => {
    const state: any = getState();
    let marketPrice;
    // check if we already have loaded market price
    if (state.app.loadingMarketPrice === false && state.app.marketPrice) {
      // go get marketPrice from app.state
      marketPrice = state.app.marketPrice;
    } else {
      // we don't have marketPrice in app.state, so go get it
      try {
        const originalPromiseResult = await dispatch(
          loadMarketPrice({ networkID: networkID, provider: provider }),
        ).unwrap();
        marketPrice = originalPromiseResult?.marketPrice;
      } catch (rejectedValueOrSerializedError) {
        // handle error here
        console.error("Returned a null response from dispatch(loadMarketPrice)");
        return;
      }
    }
    return { marketPrice };
  },
);


const loadMarketPrice = createAsyncThunk("app/loadMarketPrice", async ({ networkID, provider }: IBaseAsyncThunk) => {
  let marketPrice: number;
  try {
    marketPrice = await getMarketPrice({ networkID, provider });
    marketPrice = marketPrice / Math.pow(10, 9);
  } catch (e) {
    marketPrice = await getTokenPrice("supreme");
  }
  return { marketPrice };
});

interface IAppData {
  readonly circSupply: number;
  readonly currentTime?: number;
  readonly marketCap: number;
  readonly marketPrice: number;
  readonly stakingAPY?: number;
  readonly stakingRebase?: number;
  readonly totalSupply: number;
  readonly treasuryBalance?: number;
  readonly endTime?: number;
}

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    fetchAppSuccess(state, action) {
      setAll(state, action.payload);
    },
  },
  extraReducers: builder => {
    builder
      .addCase(loadAppDetails.pending, state => {
        state.loading = true;
      })
      .addCase(loadAppDetails.fulfilled, (state, action) => {
        setAll(state, action.payload);
        state.loading = false;
      })
      .addCase(loadAppDetails.rejected, (state, { error }) => {
        state.loading = false;
        console.error(error.name, error.message, error.stack);
      })
      .addCase(loadMarketPrice.pending, (state, action) => {
        state.loadingMarketPrice = true;
      })
      .addCase(loadMarketPrice.fulfilled, (state, action) => {
        setAll(state, action.payload);
        state.loadingMarketPrice = false;
      })
      .addCase(loadMarketPrice.rejected, (state, { error }) => {
        state.loadingMarketPrice = false;
        console.error(error.name, error.message, error.stack);
      });
  },
});

const baseInfo = (state: RootState) => state.app;

export default appSlice.reducer;

export const { fetchAppSuccess } = appSlice.actions;

export const getAppState = createSelector(baseInfo, app => app);
